// @import url(https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900);
// @import url(https://fonts.googleapis.com/css?family=Herr+Von+Muellerhoff&subset=latin-ext);
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

//FONT Flaticon
@font-face {
  font-family: "Flaticon";
  src: url("#{$theme-font-path}Flaticon.eot");
  src: url("#{$theme-font-path}Flaticon.eot?#iefix") format("embedded-opentype"),
       url("#{$theme-font-path}Flaticon.woff") format("woff"),
       url("#{$theme-font-path}Flaticon.ttf") format("truetype"),
       url("#{$theme-font-path}Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("#{$theme-font-path}Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
font-style: normal;
}

.flaticon-art:before { content: "\f100"; }


