/*
 * Global variables
 */
$image-theme-path                : '../image/' !default;
$image-theme-skin                : '../image/' !default;
$theme-font-path                 : '../fonts/' !default;
// standard colors
$white		    : #FFF !default;
$black			: #000 !default;
$orangered  	: #D32027 !default;
$orange 		: #ffc32c !default;
$nocolor		: transparent !default;
$light-gray		: #CCC !default;
$grey   		: #979797 !default;



// color for default theme
$theme-color    : #444 !default; /* main color which will be used for all main block styles... */
$border-color	: #ebebeb !default;

$text-color: $theme-color !default;

$theme-color-default             : $link-color !default;
$theme-color-second  			 : #b6b7b6 !default;
$theme-color-third  			 : #f00 !default;

// Theme Margin, Padding
// -------------------------
$theme-margin    : 30px !default;
$theme-padding   : 30px !default;
// fonts
$font-family-default: $font-family-base !default;
$font-family-third: $font-family-base !default;
$font-family-second :  $font-family-base !default;
$theme-padding : 30px !default;
/**
 * Import component variables
 */


@import "vars/elements";
@import "vars/product";
@import "vars/layout";
