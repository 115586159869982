//newleter
.pav-newsletter {
    width: 100%;
    max-width: 570px;
    margin: 0 auto;
    .form-email {
        padding: 0px 20px;
        position: relative;
        .valid {
            position: absolute;
            top: 55px;
            width: calc(100% - 40px);
            z-index: 2;
            .alert {
                width: auto;
                margin-top: 20px;
                @include rtl-text-align-left();
            }
        }
    }
    .submit-subscribe {
        width: 120px;
        .btn-v2 {
            width: 100%;
            font-size: 12px;
            height: 50px;
            border-radius: 30px;
            background-color: $orangered;
            color: $white;
            font-weight: bold;
            text-transform: uppercase;
            @include transition(all 0.5s ease 0s);
            &:hover{
                color: $black;
            }
        }
    }
    .email-subscribe {
        width: calc(100% - 125px);
        .form-control {
            border: 1px solid #e1e1e1;
            border-radius: 30px;
            height: 50px;
        }
    }
}