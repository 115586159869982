// Stylesheet

@import "bootstrap";
@import "mixins/rtl";
@import "mixins/function";
@import "variables";
@import "layout";
@import "nav";
@import "bootstrap-theme";
@import "product";
@import "elements";
@import "pages";
@import "fonts";
@import "offcanvas";
@import "modules";
@import "widget";
@import "animation";
@import "modules/deals";
@import "modules/newsletter";
@import "modules/blogs";
@import "modules/testimonial";
@import "modules/autosearch";
@import "responsive";


.product-block .name a {
    font-size: 24px;
    font-weight: bold;
}

.layerslider-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-height: 600px;

    .rev_slider {
        max-height: 600px !important;
    }
}

.tparrows:before {
    color: #fff;
}

.navbar {
    border: 0;
}
footer .list-unstyled li a {
    color: #ccc;
}

.tab-group .nav>li>a {
    font-size: 16px;
}

#content {
    img {
        max-width: 100%;
        height: auto;
    }
}

#topbar {
    .autosearch-wrapper,
    .quick-settings {
        margin-top: 30px;
    }
}

#product {
    h3 {
        font-size: 20px;
    }
}

@media (min-width: 992px) {
    .product-block .action {
        bottom: 100%;
    }
}

@media (max-width: 768px) {
    #topbar .quick-settings {
        right: 0;
        width: auto;
    }

    .logo {
        text-align: left;
    }
}