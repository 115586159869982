ul,
ol {
	list-style: none;
}
td {
  button, a ,input{
   // margin: 5px!important;
  }
  .input-group .form-control {
    width: 105px;
  }
}
.form-control {
    @include placeholder-font-family($font-family-base);
    @include box-shadow(none);
    height: auto;
    &:focus {
        border: 1px solid $border-color;
        @include box-shadow(none);
    }
}
.input-group{
  .form-control{
    z-index: 0;
  }
}
.btn-group.open .dropdown-toggle {
  @include box-shadow(none);
}
.dropdown-menu {
	padding: 20px;
    min-width: 170px;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 2px 0 0;
}
.radio + .radio,
.checkbox + .checkbox {
  margin-top: inherit; // Move up sibling radios or checkboxes for tighter spacing
}
.buttons {
    overflow: hidden;
    clear: both;
    margin: 20px 0;
}
.container-fluid {
    padding: 0;
}
.alert {
    overflow: hidden;
}
//button\
button {
    background: transparent;
    border: none;
    &:hover {
        color: $brand-primary;
    }
}
.btn {
  &:active,
  &.active {
    @include box-shadow(none);
  }
}
.btn-link {
    &:hover {
        color: $link-hover-color;
    }
}
// Links

a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: none;
  }

  &:focus {
   // @include tab-focus;
  }
}

//
// Carousel
// --------------------------------------------------
.carousel-control {
  @include transition( all 0.35s ease 0s);
	height: $carousel-control-width;
	display: inline-block;
	line-height: 28px;
  border: 2px solid #000000;
  color: $black;   
  height: 30px;
  width: 30px;
  background-color: $orangered;
	&.left {
	 left: -32px;
	}
  &.right {
      right: -32px;
  }
  &:hover{
    color: $white;
  }
}


//
// Panel
// --------------------------------------------------
.panel-footer{
  padding: 10px;
}
.panel {
  @include box-shadow(none);
  position: relative;
  border: none;
  &.panel-v2{
    .panel-title{
      font-size: 32px;
    }
    .panel-heading{
      @include rtl-text-align-left();
    }
    .panel-body{
      padding: 30px 0 0; 
    }
  }
}
.product-tabs{
  .panel{padding-top: 12px; }
}
.panel-danger{
  .panel-heading{
    background-color: $white;
  }
}
// sidebar-today-deal home 2
.sidebar-today-deal{
  .panel-heading{
    background-color: $theme-color;
    >.panel-title{
      color: $white;
    }
  }
  .carousel-control{
    border: 1px solid #ebebeb;
    &:hover, 
    &:focus{
      color: $brand-primary;
    }
  }
}

.widget-heading{
  &.panel-heading{
    @include rtl-padding(0, 0, 0, 100px);
    margin-bottom: 35px;
    &:before {
      background-color: $orangered;
      content: "";
      position: absolute;
      width: 70px;
      height: 50px;
      @include rtl-left (0px);
    }
  }
}
.container-inside{
  .widget-heading{
    &.panel-heading{
      &:before {
        height: 40px;
      }
    }
  }
}

.panel-body {
  padding: 5px 0;
}
.desciption-title{
  color: $theme-color;
}
.panel-title {
  font-size: $font-size-h1;
  color: $headings-color;
  display: inline-block;
  font-weight: $headings-font-weight;
}
.panel-group {
  .panel {
    padding: 0;
  }
  .panel-title {
    @include rtl-text-align-left();
    font-size: $font-size-base;
    &:before, &:after {
      content: none;
    }
  }
  .panel-heading{
    padding: 10px;
    background: transparent;
    .panel-title{
      font-family: $font-family-default, sans-serif;
    }
  }
}
.form-horizontal{
  >#payment-existing{
    padding: 5px;
  }
}
//
// Navs
// --------------------------------------------------
.navbar-collapse {
  padding: 0;
}
.nav-tabs {
  > li {

    > a {

    }

  }
}
.nav-first {
	> li {
	 	&:first-child {
	 		> a {
				@include rtl-padding-left(0);
	 		}
	 	}
	 	>a:hover {
	 		color: $theme-color-second;
	 	}
	 }
}

//
// Forms
// --------------------------------------------------
.radio label, .checkbox label {
    color: $link-color;
}
.list-group {
    margin: 0;
    padding: 0;
    > li {
        padding: 0;
        a{
          &:hover{ color: $megamenu-dropdown-link-hover-color; }
        }
        &:first-child{
          > a{
            border-top: none;
          }
        }
        > a {
            padding: 10px 20px;
            font-size: 16px;
            display: block;
            position: relative;          
        }
        .accordion-heading {
            position: absolute;
            top: 15px;
            font-size: 10px;
            color: $theme-color-default;
            @include rtl-right(20px);
            color: #696969;
            cursor: pointer;
            display: block;
            font-size: 7px;
            height: 20px;
            line-height: 18px;
            position: absolute;
            right: 20px;
            text-align: center;
            top: 15px;
            width: 20px;
        }
    }
    ul {
        padding: 0 0 20px;
        > li{
            list-style: none;
            border: none;
            a {
              line-height: 30px;
              @include rtl-padding-left(40px);
              font-size: 13px;
                &:hover {
                    color: $megamenu-dropdown-link-hover-color;
                }
            }
        }
    }
    .head {
        display: none;
    }
}

//well
.well {
  min-height: 20px;
  margin-bottom: 20px;
  @include box-shadow(none);

}
.pagination {
	margin: 0;
  > li {
    @include rtl-float-left();
    margin: 0 2px;
    &:first-child{
      > a,
      > span{
        @include rtl-border-left(none);
        @include rtl-padding-left(0);
      }
    }
    > a,
    > span {
      padding: 0;
      @include rtl-padding-right(20px);
      &:hover,&:active,&:focus{
       // border-left-color: $border-color;
      }
    }
  }
}
// Add a hover state for linked versions only
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: transparent;
}
.img-thumbnail {
	border: none;
}
//label
.control-label {
    font-weight: normal;
    color: #000;
}

div.required .control-label:before {
    content: '* ';
    color: #F00;
    font-weight: bold;
}
button.close {
	padding: 0 10px;
}
.img-responsive {
	display: inline-block;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	//text-transform: uppercase;
}