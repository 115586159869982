@media (min-width: $screen-lg){
    .margin-style{
        margin: 0 80px;
    }
}
.margin-style{
    margin: 0 10px;
}
@media (max-width: $screen-md-max){
    .product-tabs{
        .product-block{
            .bottom{
                border: none;
                display: block;
                .cart{
                    .btn{
                        @include border-radius(36px);
                        border: 1px solid #ebebeb;
                        &:hover{
                            border-color:transparent;
                        }
                    }
                }
                > div{
                    display: block;
                    float: none;
                }
            }      
        }
    } 
	table {
		button, a , input{
			margin-top: 10px;
		}
		input {
			min-width: 50px;
		}
	}
    .carousel-inner {
        > .item {
            > img,
            > a > img {
              max-width: 70px !important;
            }
        }
    }
    .product-info{
        .quickview-thumbnails{
            .carousel-inner {
                > .item {
                    > img,
                    > a > img {
                      max-width: 54px !important;
                    }
                }
            }
        }
    }
    .feature-category-v2{
        .widget-heading{
            &.panel-heading{
                @include rtl-padding(0, 0, 0, 300px);
                &:before {
                  @include rtl-left (200px);
                }
            }
        }
    }
}
@media (width: 1024px) {
    .product-list-v1{
        .product-meta{
            .rating{
                display: none;
            }
            h6.name{
                margin: 0;
            }
        }
    }
}
/*$media (min-width: 992px) and (max-width: 1199px)*/
@media (min-width: $screen-md) and (max-width: $screen-md-max){
    .header-v2 {
        #logo-theme {
            padding: 38px 20px;
        }
    }
    .pav-verticalmenu .navbar-nav{
        padding: 0 10px;
        > li > a{
            font-size: 12px;
        }
    }
    #column-left, #column-right {
        .product-block {
            .price {
                > span {
                    float: none!important;
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .carousel-inner {
        > .item {
            > img,
            > a > img {
              max-width: 60px !important;
            }
        }
    }

    .breadcrumb{
        h2, ul{padding: 10px 0;}
    }
    .interactive-banner {
        .content{
            .interactive-profile{
                .htmlcontent{
                    font-size: 10px;
                }
            }
        }  
        .action-button span {
            padding: 5px 10px;
            font-size: 8px;
        }
    }
    .interactive-banner-v2,.interactive-banner-v3 {
        .content{
            .interactive-profile{
                .htmlcontent{
                    .text1{
                        font-size: 20px;
                        margin-bottom: -10px;
                    }
                    .text2{
                        font-size: 20px;
                        margin-bottom: -10px;
                    }
                    .text3{
                        font-size: 25px;
                        @include rtl-margin-left (-5px);
                    }
                }
            }
        }
    }
    .interactive-banner-v4{
        .content{
            .interactive-profile{
                .htmlcontent{
                    margin-top: 20px;
                    .text1{
                        color: #7c7c7c;
                        font-size: 15px;
                    }
                    .text2{
                        font-size: 20px;
                        margin-bottom: -10px;
                        color: $white;
                    }
                    .text3{
                        font-size: 20px;
                        color: $white;
                    }
                }
            }
        }       
    }
    .interactive-banner-v5{
        .content{
            .interactive-profile{
                .widget-heading{
                    font-size: 10px; 
                }
                .htmlcontent{
                    .text1{
                        color: $white;
                        font-size: 15px;
                    }
                    .text2{
                        font-size: 8px;
                        margin: 0 0 20px;
                    }
                }
            }
        }
    }
    .interactive-banner-v6{
        .content{
            .interactive-profile{
                padding-top: 20px;
                .widget-heading{
                    font-size: 12px;
                }
            }
        }
    }

    .interactive-banner-v7{
        .widget-heading{
            font-size: 30px;
        }
        .content{
            .interactive-profile{
                padding-top: 20px;
                .htmlcontent{
                    .text1{
                        color: $white;
                        font-size: 15px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
/*$media (min-width: 768px) and (max-width: 991px) {*/

@media (min-width: $screen-sm-max){
    .listproduct {
        .products-block{
            @include rtl-padding-left(0);
        }
    }
}    

@media (max-width: $screen-md-max){
    
    .logo{
        text-align: center;
    }
    .productdeals{
        .item-countdown{
            bottom: 10px;
        }
        .product-block{
            .action{
                height: 90px;
            }
        }
    }
}    
//$media(max-width:991px)
@media(max-width: $screen-sm-max){
    .product-block .btn-style {
        border-right: none;    
    }
    .feature-category-v2,.feature-category-v3{
        .caption {
            margin: 0 -50% 0 0;
        }
    }
    .feature-category-v2{
        .widget-heading{
            &.panel-heading{
                @include rtl-padding(0, 0, 0, 100px);
                &:before {
                  @include rtl-left (0px);
                }
            }
        }
    }
    .pav-container.pav-slideshow {
        margin-top: 0;
    }
}
@media(max-width: 768px){
    #button-cart{  
        i{
            @include size(60px,43px);
            font-size: 18px;
        }
    }
    #topbar {
        #logo-theme{
            padding-top: 65px;
        }
        .quick-settings{
            position: absolute;
        }
    }
    .header-v2{
        #logo-theme {
            padding: 80px 20px 20px;
        }
        #topbar{
            position: absolute;
        }
    }
    .breadcrumb{
        padding: 30px 5px;
        h2{font-size: 20px;}
        a{font-size: 12px; white-space: pre-line;}
        li{padding: 0 10px;}
    }
}
@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
}
@media (max-width: $screen-sm-max) {
	#content, #column-right, #column-left {
	    width: 100%;
	    clear: both;
	}
	.zoomContainer {
		display: none;
	}
    .layerslider-wrapper {
        max-width: 100%!important;
    }
    #compare-total {
        margin-top: 20px;
    }
    .scrollup {
        bottom: 15px;
    }
    .products-block .product-col{
        border:none;
    }
    .action {
        margin-top: 10px;
    }
    .margin-bottom-115{
        margin-bottom: 0;
    }
    
    .box-support.bg-v2 {
        padding: 100px 0 150px;
    }
    .navbar-nav{
        .new,.hot{
            @include rtl-left(30px);
        }
    }
    // .header-v2{
    //     #cart{
    //         padding: 8px 15px;
    //     }
    // }
    /*******************************/
}
@media (max-width: $screen-sm){
    .quickview, .zoom{
        display: none!important;
    }
}
/*$media (max-width: 767px) {*/
@media (max-width: $screen-xs-max) {

	.common-home .alert {
        margin-top: 5px;
	}
    #topbar .list-inline{
        text-align: left;
        li{
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .widget-images{
        text-align: center;
    }
    .panel{
        margin-bottom: 30px;
    }
    .productdeals ul.list{
        margin-bottom: 40px;
    }
    .product-info {
        .thumbs-preview.default {
            .carousel-control {
                &.left {
                    left: 0;
                }
                &.right {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
    #topbar .btn-link{
        @include rtl-margin-right(10px);
    }
    .thumbs-preview img {
        width: 50%;
    }
}
/*$media (max-width: 480px) {*/
@media (max-width: $screen-phone) {
    .tparrows {
        @include size(40px,40px);
        &:before {
            font: 30px/35px "FontAwesome";
            @include size(40px,40px);
        }
    }
    footer{
        .panel-heading {
            padding: 0;
        }
        .footer-center {
            min-height: 1040px;
        }
        .container-footer{
            
            .container-footer-top{
                &:before{
                    background-color: transparent;
                }
            }
        }
    } 
    .tab-group .nav > li > a{
        &:before{
            content: none;
        }
        padding: 0 10px;
        font-size: 12px;
    }
    .tab-group .tab-content{
        padding: 25px;
    }
    #button-cart i{
        font-size: 14px;
        margin-right: 10px;
        width: 50px;
    }
    .pavdeals-deals,.common-home{
        .alert{
            margin-left: 20px;
            margin-right: 20px;
        }
    }
     
    footer .inner {
        padding: 20px 30px;
    }
    .list-inline{
        &.social{
            margin: 0;
        }
    } 
    
    .btn-slider3 a {
        padding: 5px 10px !important;
    }
    .btn-slider4 a{
        padding: 5px !important;
    }
    .feature-category {
        .item-category{
            margin: 10px 0;
        } 
    }
}
@media (max-width: 320px) {
    footer .footer-center {
        min-height: 1080px;
    }
    #cart .dropdown-menu{
        min-width: 300px;
    }
    /********************************/
	.review-form-width .panel-body {
	    padding: 0;
	}
	.sidebar-offcanvas {
		width: 70%;
	}
	.row-offcanvas-left.active {
	    left: 70%;
	}
	.row-offcanvas-left .sidebar-offcanvas {
	    left: -70%;
	}
    .call-to-action{
        .btn-style{
            padding: 10px 20px;
        }
        .htmlcontent{
            padding: 10px 0;
        }
        .widget_heading {
            h1.widget_title{font-size: 18px;}
        }
    } 
    .product-tabs{
        .nav-tabs {
            >li{
                >a{font-size: 12px;}
            }
        }
    }
    .panel-title{font-size: 30px;}
    #product .action .wishlist, #product .action .compare {
        padding: 0;
    }
    .page-pavblog-blog .pav-blogs h2{font-size: 25px;}

    .feature-box-v1,.feature-box-v2 {
        .fbox-body {
            padding: 20px;
        }
    }
    .feature-category-v2{
        .widget-heading{
            &.panel-heading{
                @include rtl-padding(0, 0, 0, 100px);
                &:before {
                  @include rtl-left (0px);
                }
            }
        }
    }
    .widget-heading{
        &.panel-heading{
            @include rtl-padding(0, 0, 0, 70px);
            &:before {
              width: 50px;
            }
        }
    }
    .product-tabs .nav-tabs > li > a{
        padding: 10px;
    }
    .interactive-banner-v2,.interactive-banner-v3 {
        .content{
            .interactive-profile{
                .htmlcontent{
                    .text1{
                        font-size: 10px;
                        margin-bottom: 0px;
                    }
                    .text2{
                        font-size: 10px;
                        margin-bottom: 0px;
                    }
                    .text3{
                        font-size: 10px;
                        @include rtl-margin-left (-5px);
                    }
                }
            }
        }
    }
    .pav-container-responsive{
        margin: 0 0 20px !important;
        padding: 0 0 20px !important;
    }
    .call-to-action .btn-style {
        margin-bottom: 20px;
    }


}
