
.productdeals {
    .product-block .product-meta{
        overflow: visible;
        .price{
            .price-new{
                color: $brand-primary;
                font-size: $font-size-large;
                font-weight: bold;
            }
        }
    }
    ul.list{
        border-bottom: 1px dotted $border-color;
        margin-bottom: 90px;
        padding: 10px 0;
        li{
            display: inline-block;
            float: none;
            >a {
                position: relative;
                padding: 0 10px;
                &:before{
                    content: "/";
                    position: absolute;
                    left:-5px;
                    top: -3px;
                    color: $text-color;
                    font-weight: normal;
                }
            }
            &:first-child{
                >a{
                    &:before{
                        content: none;
                    }
                }
            }
        }
    }
    .deal-style{
        position: relative;
        &:before{
            background-color: $theme-color;
            bottom: 0;
            content: "";
            @include rtl-right(-30px);
            position: absolute;
            -webkit-transform: skewY(177deg);
            -ms-transform: skewY(177deg); 
            -o-transform: skewY(177deg);
            transform: skewY(177deg);
            @include size(100%,107%);
        }
        .product-block{
            position: relative;
            z-index: 10;
            @include rtl-padding(20px,70px,20px,15px);            
            .action{
                display: none;
            }
            &:before{
                background-color: $white;
                content: "";
                height: 100%;
                @include rtl-left(-30px);
                position: absolute;
                top: 0;
                -webkit-transform: skewY(4deg);
                -ms-transform: skewY(4deg); 
                -o-transform: skewY(4deg);
                transform: skewY(4deg);
                width: 100%;
                z-index: 0;
                @include box-shadow(0 15px 25px rgba(0, 0, 0, 0.1));
            }
        }
        .item-countdown-v1{
            position: relative;
            ul{
                text-align: center;
                color: $white;
                @include rtl-padding(20px,0,40px,100px);
                font-size: 11px;
                li{
                    display: inline-block;
                    position: relative;
                    font-style: italic;
                    @include size(45px,45px);
                    &:before{
                        content: "";
                        position: absolute;
                        @include rtl-left(0);
                        @include size(1px,30px);
                        top: 18px;
                        background: rgba(255,255,255,0.3);
                    }
                    &:first-child{
                        &:before{
                            content: none;
                        }
                    }
                   .countdown_num{
                        font-size: 18px;
                        font-weight: bold;
                        font-family: $headings-font-family;
                        font-style: normal;
                        border-bottom: 1px dotted $white;
                   }                   
                }
            }
        }     
    }
	.item-countdown {  
	    margin: 10px auto;
        width: 200px;
        border: 1px solid #ebebeb;
		ul {
			margin-bottom: 0;
		}
		li {
			text-align: center;			
			cursor: pointer;
			display: inline-block;
            
            background: rgba(255,255,255,0.8);
            @include size(42px,42px);
            line-height: 0;
            padding-top: 2px;
            position: relative;
            span{
                display: inline-block;                    
                margin-bottom: 3px;
                color: rgba(0,0,0,0.54);
                font-size: 10px;
                position: inherit;
                font-style: italic;
            }
            &:before {
                position: absolute;
                content: ":";
                left:0;
                top: 17px;
                color: rgba(0,0,0,0.54);
                font-size: 18px;
            }
            &:first-child{
                &:before{
                    content:none;
                }
            }
			.countdown_num {
                line-height: normal;
                span{
                    font-family: $font-family-base;
                    font-size: 18px;
                    font-weight: bold;
                    font-style: normal;
                    color: $black;
                }
				
			}
		}
	}
}
.pavdeals.tab-group {
   .nav{
        > li{
            > a{
                &:before{
                    @include rtl-left(50%);
                    @include rtl-margin-left(-30px);
                }
            }
        }
   }
}

.productdeals-categories{
    .item-countdown{
        li {
            &:before {
                @include rtl-left(0);
            }
        }
    }
}