.autosearch-wrapper {
    background:$white;
    color: $theme-color-default;
    position: relative;
    //@include border-radius(3px);
    margin: 10px;
    .input-group-btn {
        &:after {
            line-height: 38px;
            position: absolute;
            @include rtl-right(0);
            text-align: center;
            top: 0px;
            font-family: 'FontAwesome';
            content: "\f107";
            font-size:16px;
            //@include size(50px,38px);
            color: #ffffff;
            pointer-events: none;  
            @include rtl-right(55px);
        }
    }
    .category_filter{
        border: none;
        display: inline-block;
        font-size: 12px;
        height: 40px;
        line-height: 30px;
        @include rtl-padding (0px, 162px, 0px, 20px);
        @media (max-width: 1024px) {
            @include rtl-padding (0px, 50px, 0px, 20px);
        }
        max-width: 270px;
        background-color: #2e363d;
        color: $white;
    }
    .search_query{
        background-color: transparent;           
        border: 2px solid $border-color; 
        height: 40px;
        font-family: "Droid Serif",serif;
        font-size: 12px;
        color:#c5c5c5;
        font-style: italic;
        &:focus {
            @include box-shadow(inherit);
        }
    }
    .input-group {
        width: 100%;
    }
    .button-search {
        position: absolute;
        @include rtl-right(-1px);
        border: 0;
        font-size: 12px;
        text-align: center;
        height: 40px;
        padding:0 15px;
        top: 0px;
    }
    .dropdown-menu {
        z-index: 999;
        > li {
            &.clearfix {
                border-bottom: 1px dashed $border-color;
                margin-left: 10px;
                margin-right: 10px;
            }
            &:last-child {
                border-bottom: none;
            }
            > a {
                padding: 0;
            }
            .media-body{
                @include rtl-padding-left(10px);
            }
        }
    }
}